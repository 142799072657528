import { Avatar, Container, Grid, Box, Typography, useTheme } from '@mui/material';
import monash_logo from './imgs/monash-logo.png';
import marie from './imgs/marie.jpg';
import wansim from './imgs/wansim.jpg';
import olivia from './imgs/olivia.jpg';
import liz from './imgs/liz.jpg';
import tony from './imgs/tony.png';
import rabba from './imgs/rabba.png';
import action_lab from './imgs/action-lab.png';
import { ExtLink, Heading } from 'components';
import { styled } from '@mui/system';

export function About() {
  const theme = useTheme();

  return (
    <Container
      sx={{
        marginTop: theme.spacing(8),
      }}>
      <Box mt={2} mb={4}>
        <Grid container spacing={4}>
          <Grid item xs={12} textAlign="center"> 
            <Heading>
              About Us
            </Heading>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" paragraph>
              <em>Partners in Parenting</em> (PiP) aims to bridge the gap between complex research evidence and practical resources for parents.
            </Typography>
            <Typography variant="body1" paragraph>
              We know that parents play an important role in protecting their child’s mental wellbeing. However, despite extensive research supporting 
              the role parents can play in reducing their child’s risk of mental health problems, until recently this information was not easily accessible to parents.
            </Typography>
            <Typography variant="body1" paragraph>
              PiP brings together decades of high-quality research evidence and the consensus of international experts on parenting and child and youth mental health.
              Parents can access this information via interactive, online programs, designed to build skills and confidence in ways that may protect their child from depression and anxiety disorders.
            </Typography>
            <Typography variant="body1" paragraph>
              To date, we’ve conducted multiple research trials with over 4000 Australian parents. 
              Our results show that PiP is effective, with benefits for both parents and children.
            </Typography>

            <Typography variant="subtitle1">
              <strong>Parenting Autistic Children: PiP Kids-Autism</strong>
            </Typography>
            <Typography variant="body1" paragraph>
              We know that depression and/or anxiety disorders are common in young autistic people. Drawing on extensive co-design work we did with parents of autistic children and professionals 
              who support these families, and feedback from parents who have completed previous versions of PiP, we have added new program content to better support parents of autistic children.
            </Typography>
            <Typography variant="body1" paragraph>
              The PiP Kids-Autism program includes four new modules and strategies to effectively support the mental health of autistic children. 
            </Typography>

            <Typography>
              If you’d like to read more about the research behind PiP, get in touch with us at {' '}
              <ExtLink href="mailto:pipkids-autism@monash.edu">pipkids-autism@monash.edu</ExtLink>.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Heading>
              The people behind <em>PiP Kids-Autism</em>
            </Heading>
          </Grid>

          <Grid item xs={12} sm={6} container alignItems="center" style={{ order: 3 }}>
            <Box>
              <Typography variant="h5">Prof Marie Yap</Typography>
              <Typography variant="body1" paragraph>
                Marie is a Professor, Psychologist, and Head of the Parenting and Youth Mental Health Research Group at Monash University.
                Marie is the founder and lead researcher of the Partners in Parenting program and has been recognised as a leading international expert in family relationships.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} style={{ order: 4 }}>
            <Box sx={avatarBoxStyle}>
              <Avatar src={marie} sx={avatarStyle} />
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              order: 5,
              [theme.breakpoints.down('sm')]: {
                order: 6,
              },
            }}>
            <Box sx={avatarBoxStyle}>
              <Avatar src={wansim} sx={avatarStyle} />
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            container
            alignItems="center"
            sx={{
              order: 6,
              [theme.breakpoints.down('sm')]: {
                order: 5,
              },
            }}>
            <Box>
              <Typography variant="h5">Dr Wan Sim</Typography>
              <Typography variant="body1" paragraph>
                Wan is a Research Fellow in the Parenting and Youth Mental Health Group and the Complex Autism and Neurodevelopment
                Research Group at Monash University, and Senior Psychologist at the Royal Children’s Hospital Melbourne.
                Motivated to bring science and service to healthcare, she leads and conducts research that aims to blend knowledge from 
                researchers, practitioners, and the people with lived experience. Wan has played a key role in the PiP Kids projects to date.
              </Typography>
            </Box>
          </Grid>


          <Grid item xs={12} sm={6} container alignItems="center" style={{ order: 7 }}>
            <Box>
              <Typography variant="h5">Emeritus Prof Tony Jorm</Typography>
              <Typography variant="body1" paragraph>
                Tony is a Professor Emeritus at the University of Melbourne and an NHMRC Leadership Fellow. His research
                focuses on public knowledge and beliefs about mental illness, and interventions to improve the public’s
                helpfulness towards people developing mental illness. Tony has been a key contributor to all PiP
                projects to date.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} style={{ order: 8 }}>
            <Box sx={avatarBoxStyle}>
              <Avatar src={tony} sx={avatarStyle} />
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              order: 9,
              [theme.breakpoints.down('sm')]: {
                order: 10,
              },
            }}>
            <Box sx={avatarBoxStyle}>
              <Avatar src={olivia} sx={avatarStyle} />
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            container
            alignItems="center"
            sx={{
              order: 10,
              [theme.breakpoints.down('sm')]: {
                order: 9,
              },
            }}>
            <Box>
              <Typography variant="h5">Olivia Bruce</Typography>
              <Typography variant="body1" paragraph>
                Olivia is completing her PhD in Clinical Psychology at Monash University with the Parenting and Youth Mental Health Group. 
                Olivia’s PhD research focuses on how we can empower parents of autistic children to support their child’s mental health. 
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} container alignItems="center" style={{ order: 13 }}>
            <Box>
              <Typography variant="h5">Liz Nicoloau</Typography>
              <Typography variant="body1" paragraph>
                Liz is a Research Officer in the Parenting and Youth Mental Health research group at Monash University and 
                has been instrumental in the set up of the PiP Kids-Autism program. 
              </Typography>
            </Box>
          </Grid>


          <Grid item xs={12} sm={6} style={{ order: 14 }}>
            <Box sx={avatarBoxStyle}>
              <Avatar src={liz} sx={avatarStyle} />
            </Box>
          </Grid>


          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              order: 15,
              [theme.breakpoints.down('sm')]: {
                order: 16,
              },
            }}>
            <Box sx={avatarBoxStyle}>
              <Avatar src={rabba} sx={avatarStyle} />
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            container
            alignItems="center"
            sx={{
              order: 16,
              [theme.breakpoints.down('sm')]: {
                order: 15,
              },
            }}>
            <Box>
              <Typography variant="h5">Dr Aspasia Stacey Rabba</Typography>
              <Typography variant="body1" paragraph>
                 Stacey is an Educational and Developmental Psychologist and Lecturer at Monash University. She is a Senior Psychologist at Monash Health 
                 and leads an adult autism service in the mental health program. Her research focuses on neurodevelopmental conditions across the lifespan,
                 supporting wellbeing in families and individuals. As a scientist-practitioner, she is passionate about translating research into practice. Stacey has been a key contributor to the PiP Kids-Autism program. 
              </Typography>
            </Box>
          </Grid>


         

          <Grid item xs={12} sm={6} container alignItems="center" style={{ order: 17 }}>
            <Box>
              <Typography variant="h5">Action Lab</Typography>
              <Typography variant="body1" paragraph>
                Action Lab is a multidisciplinary team of impact-focused researchers in the department of Human-Centred
                Computing at Monash University, Australia. They recently joined the PiP team to co-design the most
                recent versions of PiP, with the latest technology and their domain expertise in computer science,
                human-centred design methods, interaction design and digital mental health.
              </Typography>
              <Typography variant="body1" paragraph>
                The Action Lab project team is led by Professor Patrick Olivier and includes a highly energetic and
                creative team of researchers and research engineers: Dr Tom Bartindale, Dr Jue (Grace) Xie, Dr Roisin
                McNaney, Joshua Seguin, Harrison Marshall and Dr Ling Wu.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} style={{ order: 18 }} container alignContent="center">
            <Box sx={avatarBoxStyle}>
              <ActionLogoImage src={action_lab} height="100%" />
            </Box>
          </Grid>
        </Grid>

        <Box mt={2} mb={4}>
          <Box mb={4}>
            <Heading>Acknowledgements</Heading>
          </Box>

          <Typography variant="body1" paragraph>
            The <em>Partners in Parenting</em> program is part of a suite of parenting resources from the{' '}
            <ExtLink href="https://www.parentingstrategies.net">
              <em>Parenting Strategies Program</em>
            </ExtLink>
            , which was first established in 2010. Throughout its 14-year history, the program has had many contributors
            and funders, whom we cannot all acknowledge by name here. Nonetheless, special thanks go to the following
            contributors to the <em>Partners in Parenting</em> program: Professor Emeritus Tony Jorm (University of Melbourne),
            Associate Professor Katherine Lawrence (Monash University), Distinguished Professor Ron Rapee (Macquarie University), 
            Maddy Fernando, Grace Aldridge and all the staff, students, and volunteers of the Parenting and Youth Mental Health Group from 2016 to 2024. 
            We thank Monica Lam (Royal Children’s Hospital Melbourne) and Professor Katrina Williams (Monash Health & Monash University) for their contributions to the PiP Kids Autism program.
          </Typography>

          <Typography variant="body1" paragraph>
            We also thank the parents and young people who have contributed feedback throughout the different stages of
            the PiP journey.
          </Typography>

          <Typography variant="body1" paragraph>
            We acknowledge with thanks the funding which have supported the development and evaluation of the program
            from 2011-2024, including: National Health and Medical Research Council (APP IDs 2005621, 566652, 1061744,
            400001), Medical Research Future Fund (GNT2005621, MRF2006438) Australian Rotary Health, Monash University, and Helen Macpherson Smith Trust.
          </Typography>
        </Box>
      </Box>

    </Container>
  );
}

const avatarBoxStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  textAlign: 'center',
};

const avatarStyle = {
  width: 200,
  height: 200,
};

const MonashLogoImage = styled('img')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '70%',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
}));

const ActionLogoImage = styled('img')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '60%',
  },
  [theme.breakpoints.down('md')]: {
    width: '90%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '80%',
  },
}));

const PuLogo = styled('img')(({ theme }) => ({
  width: '350px',
  height: '110px',
  [theme.breakpoints.down('md')]: {
    width: '250px',
    height: '78px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '200px',
    height: '64px',
  },
}));
